@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
export const ThemeOne: ColourTheme = {
    colourOne: "#606C38", // light green
    colourTwo: "#283618", // dark green
    colourThree: "#FEFAE0", // cream
    colourFour: "#DDA15E", // light brown
    colourFive: "#BC6C25", // dark brown
  };
  
  export const ThemeTwo: ColourTheme = {
    colourOne: "#FFD9DA", // light pink
    colourTwo: "#EA638C", // pink
    colourThree: "#89023E", // magenta
    colourFour: "#30343F", // grey
    colourFive: "#1B2021", // black
  }; */


.light-mode {
    --color-primary: #a3b762;
    --color-primaryFocussed: #606C38;
    --color-secondary: #FEFAE0;
    --color-secondaryFocussed: #fff4aa;
    --color-tertiary: #DDA15E;
    --color-tertiaryFocussed: #BC6C25;
    --color-warning: #DDA15E;
    --color-warningFocussed: #BC6C25;
    --color-plain: #c81414;
    --color-plainFocussed: #BC6C25;
    --color-paper: #f1f1f1;
    --color-contrastText: "#283618";
    background-color: #fffffb;
}

.dark-mode {
    --color-primary: #ad1c48;
    --color-primaryFocussed: #E22f65;
    --color-secondary: #30343F;
    --color-secondaryFocussed: #5c606c;
    --color-tertiary: #940f6a;
    --color-tertiaryFocussed: #c91490;
    --color-warning: #DDA15E;
    --color-warningFocussed: #BC6C25;
    --color-plain: #0d1314;
    --color-plainFocussed: #BC6C25;
    --color-paper: #0d1314;
    --color-contrastText: "#EA638C";
    color: #E3E3E3;
    background-color: #1B2021;
}

.rotate {
    animation: rotation 2s;
}

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
}

.linear {
    animation-timing-function: linear;
}

.infinite {
    animation-iteration-count: infinite;
}

.rotate-loop {
    animation: rotation 2s linear infinite;
}

/* body {
    background-color: #2B2C2C;
} */